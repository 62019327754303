import { navigate } from '@mr-yum/frontend-core/dist/services/routes'
import {
  DiscountOutlineIcon,
  DrawerContainer,
  useBreakpoint,
  UserCircleOutlineIcon,
} from '@mr-yum/frontend-ui'
import { useLoyaltyPoints } from 'components/Rewards/hooks/useLoyaltyPoints'
import { useLogger } from 'hooks/useLogger'
import { routes } from 'lib/routes'
import { formatPoints } from 'lib/utils'
import React, { useCallback, useState } from 'react'

import { GuestProfileMenu } from './GuestProfileMenu'

interface Props {
  venueSlug: string
}

export const ProfileNavBarIcon = ({ venueSlug }: Props) => {
  const { logEvent } = useLogger()
  const [open, setOpen] = useState(false)
  const breakpoint = useBreakpoint()
  const isMobile = ['sm', 'md'].includes(breakpoint)
  const { points } = useLoyaltyPoints({ venueSlug })

  const handleOpen = useCallback(async () => {
    logEvent('Clicked on guest profile menu')

    if (isMobile) {
      await navigate(routes.guestProfile, {
        venueSlug,
      })
    } else {
      setOpen(true)
    }
  }, [isMobile, logEvent, venueSlug])

  const handleClose = useCallback(() => {
    logEvent('Closed guest profile menu')
    setOpen(false)
  }, [logEvent])

  if (points) {
    return (
      <>
        <button
          onClick={handleOpen}
          className="inline-flex flex-shrink-0 items-center justify-center gap-1.5 rounded-md border border-white/20 py-[7px] pl-3 pr-4"
          aria-label="View account"
        >
          <DiscountOutlineIcon
            width={20}
            height={20}
            className="flex-shrink-0"
          />

          <span
            className="my-label-sm"
            data-testid="profile-navbar-loyalty-points"
          >
            {formatPoints(points)}
          </span>
        </button>

        <DrawerContainer isOpen={open} onClose={handleClose} anchor="right">
          <GuestProfileMenu venueSlug={venueSlug} handleClose={handleClose} />
        </DrawerContainer>
      </>
    )
  }

  return (
    <>
      <button
        className="-mr-3 flex h-14 w-14 flex-shrink-0 items-center justify-center truncate rounded-none border-transparent bg-transparent py-3 text-foreground-ui-navbar focus:outline-none active:border-transparent"
        onClick={handleOpen}
        aria-label="View account"
      >
        <UserCircleOutlineIcon />
      </button>

      <DrawerContainer isOpen={open} onClose={handleClose} anchor="right">
        <GuestProfileMenu venueSlug={venueSlug} handleClose={handleClose} />
      </DrawerContainer>
    </>
  )
}
