import { ChevronRightIcon } from '@mr-yum/frontend-ui'
import React, { MouseEventHandler, PropsWithChildren } from 'react'

interface Props {
  icon: React.ReactNode
  title: React.ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const AccountExternalRewardsButton = ({
  icon,
  title,
  children,
  onClick,
}: PropsWithChildren<Props>) => {
  return (
    <div className="border-b">
      <button
        className="bg-interactive-surface-subtle flex w-full flex-1 items-center justify-between py-5 text-left hover:bg-interactive-surface-hover"
        onClick={onClick}
      >
        <span className="mr-4 flex-shrink-0">{icon}</span>
        <p className="flex-grow my-body-lg">{title}</p>
        {children ?? <ChevronRightIcon className="flex-shrink-0" />}
      </button>
    </div>
  )
}
