import { redirect } from '@mr-yum/frontend-core/dist/services/redirect'
import { getCartItemCount } from 'components/Menu/utils'
import { cartItemSerializer } from 'components/Tracking/utils'
import {
  CartPaymentQuery,
  OrderingType,
  useRemoveItemFromCartMutation,
  useReorderMutation,
} from 'lib/gql'
import { getOrderingSlugFromType, routes } from 'lib/routes'
import { useCallback } from 'react'

import { useLogger } from './useLogger'

export enum EventType {
  venueLanding = 'Click reorder drinks button',
  hamburgerMenu = 'Click on reorder drinks from hamburger menu',
}

export const useReorder = (eventType: EventType) => {
  const [{ fetching: reorderFetching, error: reorderError }, reorder] =
    useReorderMutation()

  const { logEvent } = useLogger()

  const handleReorder = useCallback(
    async (venueSlug: string, orderId: string) => {
      const { data, error } = await reorder({
        venueSlug,
        orderId,
      })

      if (data && !error) {
        const orderingType = data?.reorder?.orderingType ?? null

        logEvent(eventType, {
          tableNumber: data.reorder.tableNumber,
          tableArea: data.reorder.tableArea,
          quantity: getCartItemCount({
            tableArea: data.reorder.tableArea,
            tableNumber: data.reorder.tableNumber,
            id: data.reorder.id,
            cartItems: data.reorder.cartItems,
          }),
          billTotalInCents: data.reorder.total.totalInCents,
        })

        data.reorder.cartItems.forEach((cartItem) => {
          logEvent('Add item to cart', {
            cartItem: cartItemSerializer(cartItem),
          })
        })

        if (orderingType) {
          redirect(
            routes.cart.toPath!({
              venueSlug,
              orderingTypeSlug: getOrderingSlugFromType(orderingType),
            }),
          )
        } else {
          redirect(routes.venue.toPath!({ venueSlug }))
        }
      }
    },
    [reorder, logEvent, eventType],
  )

  return {
    handleReorder,
    reorderFetching,
    reorderError,
  }
}

export const useCleanupReorder = ({
  cart,
  venueSlug,
  orderingType,
}: {
  venueSlug: string
  orderingType: OrderingType
  cart: CartPaymentQuery['getCart']
}) => {
  const [, removeItemFromCart] = useRemoveItemFromCartMutation()

  return useCallback(() => {
    return Promise.all(
      cart?.cartItems.map((cartItem) => {
        return removeItemFromCart({
          input: {
            id: cartItem.id,
            venueSlug: venueSlug,
            orderingType,
          },
        })
      }) ?? [],
    )
  }, [cart, venueSlug, orderingType, removeItemFromCart])
}
