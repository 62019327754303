import { Input, SearchOutlineIcon } from '@mr-yum/frontend-ui'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import { MenuStoreContext } from 'stores/MenuStore'

export const CategorySearchV2 = observer(() => {
  const intl = useIntl()

  const { categorySearch, setCategorySearch } = useContext(MenuStoreContext)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCategorySearch(event.target.value)
    },
    [setCategorySearch],
  )

  return (
    <div className="flex items-center justify-between p-4 md:px-8 lg:px-12">
      <Input
        className={{
          container: 'flex-1',
          input: 'placeholder:text-foreground',
        }}
        placeholder={
          categorySearch.length > 0
            ? categorySearch
            : intl.formatMessage({
                defaultMessage: 'Search',
                id: 'xmcVZ0',
              })
        }
        value={categorySearch}
        onChange={handleChange}
        clearable
        startEnhancer={
          <div className="flex items-center justify-center text-foreground">
            <SearchOutlineIcon />
          </div>
        }
      />
    </div>
  )
})

CategorySearchV2.displayName = 'CategorySearchV2'
