import { RouteLink, YumRoute } from '@mr-yum/frontend-core/dist/services/routes'
import { ChevronRightIcon } from '@mr-yum/frontend-ui'
import { useLogger } from 'hooks/useLogger'
import React, { PropsWithChildren } from 'react'

interface Props {
  icon: React.ReactNode
  title: React.ReactNode
  route: YumRoute<any>
  pathData: any
}

export const AccountLink = ({
  icon,
  title,
  route,
  pathData,
  children,
}: PropsWithChildren<Props>) => {
  const { logEvent } = useLogger()

  return (
    <div className="border-b">
      <RouteLink route={route} pathData={pathData}>
        <a
          className="bg-interactive-surface-subtle flex flex-1 items-center justify-between py-5 hover:bg-interactive-surface-hover"
          onClick={() => logEvent(`Clicked on ${title} in guest profile page`)}
        >
          <span className="mr-4 flex-shrink-0">{icon}</span>

          <p className="flex-grow my-body-lg">{title}</p>

          {children ?? <ChevronRightIcon className="flex-shrink-0" />}
        </a>
      </RouteLink>
    </div>
  )
}
