import { VenueMenuCategoryQuery } from 'lib/gql'
import flow from 'lodash/fp/flow'
import identity from 'lodash/fp/identity'
import sortBy from 'lodash/fp/sortBy'
import memoize from 'lodash/memoize'
import shuffle from 'lodash/shuffle'

export const randomizeCategories = (
  menuCategories: VenueMenuCategoryQuery['guestMenuCategories'],
  randomize?: boolean,
  randomizer = shuffleOnChange,
): VenueMenuCategoryQuery['guestMenuCategories'] => {
  /**
   * Note: Shuffle comes first so that we do not randomize on search
   */
  const randomized: VenueMenuCategoryQuery['guestMenuCategories'] = flow(
    randomize ? randomizer : identity,
  )(menuCategories)

  const featuredCategories = randomized.filter(
    (category) => category.mvoFeatured,
  )
  const otherCategories = randomized.filter((category) => !category.mvoFeatured)

  const sortedFeaturedCategories = featuredCategories.sort(
    (a, b) => (a.position || 0) - (b.position || 0),
  )
  return [...sortedFeaturedCategories, ...otherCategories]
}

const categoryChangeResolver = (
  menuCategories: VenueMenuCategoryQuery['guestMenuCategories'],
): string => {
  if (!Array.isArray(menuCategories)) {
    return JSON.stringify(menuCategories)
  }

  /*
      This needs to include any dynamic properties that can change based on the table number.
      We could JSON.stringify the whole object to be safe but there
      might be a performance hit. Do we know of any other properties which are dynamic?
    */
  return menuCategories
    .map(
      (menuCategory) =>
        `id:${menuCategory.id},isClosed:${menuCategory.isClosed}`,
    )
    .join('')
}

const shuffleOnChange = memoize(
  flow(shuffle, sortBy('isClosed')),
  categoryChangeResolver,
)
