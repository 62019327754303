import { config } from 'lib/config'

export const ORIGIN_COOKIE =
  config.environment === 'production' ? 'mryum.origin' : 'staging-mryum.origin'

export const VERIFY_PAYMENT_METHOD_VERIFICATION_RESULT_SUCCEEDED =
  'VERIFY_PAYMENT_METHOD_VERIFICATION_RESULT_SUCCEEDED'

export enum NextAction {
  /** NEXT_ACTION_UNSPECIFIED - Unspecified */
  NEXT_ACTION_UNSPECIFIED = 'NEXT_ACTION_UNSPECIFIED',
  /** VERIFY_PAYMENT_METHOD - Verification (3DS) is required */
  VERIFY_PAYMENT_METHOD = 'VERIFY_PAYMENT_METHOD',
  /** NO_ACTION_REQUIRED - No action is required, continue */
  NO_ACTION_REQUIRED = 'NO_ACTION_REQUIRED',
}
