import { useFlag } from '@unleash/proxy-client-react'
import React, { PropsWithChildren } from 'react'

interface Props {
  flag: string
}

export const FlagContainer = ({ flag, children }: PropsWithChildren<Props>) => {
  const enabled = useFlag(flag)

  return enabled ? <>{children}</> : null
}
