import { CenterSpinner } from '@mr-yum/frontend-ui'
import { Sheet } from 'components/Common/Sheet'
import { AddressForm } from 'components/YourAddress/AddressForm'
import { useLogger } from 'hooks/useLogger'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import { SheetChildProps } from './Sheets'

export const AddressSheet = ({
  cart,
  onClose,
  showBack,
  isCloseable,
  cartFetching,
}: SheetChildProps) => {
  const { logEvent } = useLogger()

  const handleOnSubmit = useCallback(() => {
    logEvent('Updated address on venue landing page')
    onClose()
  }, [logEvent, onClose])

  return (
    <Sheet
      isCloseable={isCloseable}
      showBack={showBack}
      onClose={onClose}
      title={
        <FormattedMessage defaultMessage="Enter delivery address" id="lQZ2Ad" />
      }
    >
      {cartFetching ? (
        <div className="p-4">
          <CenterSpinner />
        </div>
      ) : (
        <div className="p-4">
          <AddressForm
            onClose={handleOnSubmit}
            location={cart?.currentLocation}
          />
        </div>
      )}
    </Sheet>
  )
}
