import { Sheet } from 'components/Common/Sheet'
import { TableNumberController } from 'components/TableNumber/TableNumberController'
import { findSettingsByOrderingType } from 'components/Venue/utils/venueUtils'
import { useOrderingTypeContext } from 'contexts/VenueOrderContext'
import { useLogger } from 'hooks/useLogger'
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import { SheetChildProps } from './Sheets'

export const TableNumberSheet = ({
  venue,
  cart,
  onClose,
  showBack,
  isCloseable,
}: SheetChildProps) => {
  const { logEvent } = useLogger()

  const handleOnSubmit = useCallback(() => {
    logEvent('Updated table number on venue landing page')
    onClose()
  }, [logEvent, onClose])
  const { orderingType } = useOrderingTypeContext()
  const settings = findSettingsByOrderingType({
    venue,
    orderingType,
  })
  const tablePromptText = settings?.tablePromptText

  return (
    <Sheet
      isCloseable={isCloseable}
      showBack={showBack}
      onClose={onClose}
      title={
        tablePromptText || (
          <FormattedMessage
            defaultMessage="What’s your table number?"
            id="eFKMTQ"
          />
        )
      }
    >
      <div className="p-4">
        <TableNumberController
          onClose={handleOnSubmit}
          number={cart?.tableNumber}
          area={cart?.tableArea}
        />
      </div>
    </Sheet>
  )
}
