import { ErrorNotification } from '@mr-yum/frontend-ui'
import { FlagContainer } from 'components/Common/FlagContainer'
import { ResponsiveModal } from 'components/ResponsiveModal'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { useCurrentSheet } from 'hooks/useCurrentSheet'
import { useLogger } from 'hooks/useLogger'
import {
  CartBaseInput,
  Item,
  useAddItemsToCartMutation,
  VenueLandingAdditionalQuery,
} from 'lib/gql'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { CustomerStoreContext } from 'stores/CustomerStore'

import { AnotherRoundSheet } from './AnotherRoundSheet'

interface Props {
  isCartEmpty?: boolean
  anotherRoundCartItems?: VenueLandingAdditionalQuery['getAnotherRoundCartItems']
}

export type Quantities = Record<string, number>

export const AnotherRoundModal = ({
  isCartEmpty,
  anotherRoundCartItems,
}: Props) => {
  const { setAnotherRoundModalStatusForVenueSlug, getShowAnotherRoundModal } =
    useContext(CustomerStoreContext)
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  const [isOpen, setIsOpen] = useState(getShowAnotherRoundModal(venueSlug))
  const currentSheet = useCurrentSheet()
  const { logEvent } = useLogger()

  const [{ fetching, error }, addItemsToCart] = useAddItemsToCartMutation()

  // make sure we only show actually available menu items
  // In future we should instead show these items with a low opacity in the modal (SRV-3454)
  const availableCartItems = useMemo(
    () =>
      anotherRoundCartItems?.filter((cartItem) => {
        const { item, upsell, quantity } = cartItem

        if (quantity === 0) {
          return false
        }
        if (item) {
          return item.isAvailable && item.isAvailableForOrdering
        } else if (upsell) {
          return (
            upsell.menuItem.isAvailable &&
            upsell.menuItem.isAvailableForOrdering
          )
        }
      }),
    [anotherRoundCartItems],
  )

  const handleOnClose = useCallback(() => {
    setIsOpen(false)
    setAnotherRoundModalStatusForVenueSlug({ venueSlug, dismissed: true })
  }, [setAnotherRoundModalStatusForVenueSlug, venueSlug])

  const handleOnSubmit = useCallback(
    async (quantities: Quantities) => {
      if (!availableCartItems) {
        return
      }

      // We only want to order each item and it's modifiers
      // not cartUpsells as that could include food items (SRV-3452)
      const mappedItems = availableCartItems
        .filter(({ id }) => quantities[id] !== 0)
        .reduce<Item[]>(
          (acc, { id, item, upsell, quantity, cartModifiers }) => {
            const itemId = item?.id ?? upsell?.menuItem.id
            if (!itemId) {
              return acc
            }
            const itemToAdd: Item = {
              item: {
                id: itemId,
                quantity: quantities[id] ?? quantity,
              },
            }
            if (cartModifiers) {
              const validModifiers = cartModifiers
                .map((cartModifier) => ({
                  id: cartModifier.modifier?.id,
                  quantity: cartModifier.quantity / quantity,
                }))
                .filter(
                  (cartModifier): cartModifier is CartBaseInput =>
                    !!cartModifier.id,
                )

              itemToAdd.modifiers = validModifiers
            }

            acc.push(itemToAdd)

            return acc
          },
          [],
        )

      const { error } = await addItemsToCart({
        input: {
          venueSlug,
          orderingType,
          items: mappedItems,
        },
      })
      if (!error) {
        logEvent('Another Round items added', {
          items: mappedItems,
        })
        handleOnClose()
      }
    },
    [
      addItemsToCart,
      availableCartItems,
      orderingType,
      logEvent,
      venueSlug,
      handleOnClose,
    ],
  )

  return (
    <ResponsiveModal onClose={handleOnClose}>
      {!currentSheet && isCartEmpty && availableCartItems?.length && isOpen && (
        <FlagContainer flag="frontend.enable-another-round">
          <AnotherRoundSheet
            isSubmitting={fetching}
            cartItems={availableCartItems}
            onSubmit={handleOnSubmit}
            onClose={handleOnClose}
          >
            {error && <ErrorNotification fullWidth error={error} />}
          </AnotherRoundSheet>
        </FlagContainer>
      )}
    </ResponsiveModal>
  )
}
