import { Sheet } from 'components/Common/Sheet'
import { OrderingWindowsForm } from 'components/Ordering/OrderingWindowsForm'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { useLogger } from 'hooks/useLogger'
import { OrderingType } from 'lib/gql'
import React, { useCallback, useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { CustomerStoreContext } from 'stores/CustomerStore'

import { SheetChildProps } from './Sheets'

export const OrderingWindowSheet = ({
  cart,
  onClose,
  showBack,
  isCloseable,
}: SheetChildProps) => {
  const { logEvent } = useLogger()
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()

  const { showOrderingTypeModal } = useContext(CustomerStoreContext)
  useEffect(() => {
    showOrderingTypeModal()
  }, [showOrderingTypeModal])

  const handleOnSubmit = useCallback(() => {
    logEvent('Updated ordering window on venue landing page')
    onClose()
  }, [logEvent, onClose])

  let title = (
    <FormattedMessage defaultMessage="Select ordering window" id="namfZU" />
  )

  if (orderingType === OrderingType.Delivery) {
    title = (
      <FormattedMessage defaultMessage="Select delivery window" id="MxHwwY" />
    )
  } else if (orderingType === OrderingType.PickUp) {
    title = (
      <FormattedMessage defaultMessage="Select pickup window" id="17C4ef" />
    )
  }

  return (
    <Sheet
      isCloseable={isCloseable}
      showBack={showBack}
      onClose={onClose}
      title={title}
    >
      <OrderingWindowsForm
        venueSlug={venueSlug}
        orderingType={orderingType}
        onClose={handleOnSubmit}
        startDate={cart?.orderingWindowStartDate}
      />
    </Sheet>
  )
}
