import {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from '@mr-yum/frontend-ui'
import cn from 'classnames'
import { IconCard } from 'components/Common/IconCard'
import React from 'react'

interface Props {
  icon: React.ReactNode
  heading: React.ReactNode
  description?: React.ReactNode
  count: number
}

export type ButtonProps<C extends React.ElementType = 'button'> =
  PolymorphicComponentPropsWithRef<C, Props>

export const ActionBlockItem = React.forwardRef(
  <C extends React.ElementType = 'button'>(
    {
      icon,
      as,
      count,
      heading,
      description,
      isLoading,
      ...props
    }: ButtonProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const Component = as || 'button'

    if (count === 2) {
      return (
        <Component
          ref={ref}
          className={cn(
            'flex w-full flex-1 cursor-pointer items-center gap-4 rounded bg-interactive-surface px-4 py-3 text-interactive-foreground hover:bg-interactive-surface-hover',
            {
              'pointer-events-none opacity-60': isLoading,
            },
          )}
          disabled={isLoading}
          {...props}
        >
          {icon}

          <span className="my-label-sm">{heading}</span>
        </Component>
      )
    }

    if (count >= 3) {
      return (
        <div className="mb-6 w-full flex-col items-center">
          <Component
            as={as}
            ref={ref}
            className={cn('w-full flex-1 cursor-pointer', {
              'pointer-events-none opacity-60': isLoading,
            })}
            disabled={isLoading}
            {...props}
          >
            <div className="mb-2 flex min-h-[49px] flex-col items-center justify-center rounded px-1 transition-colors">
              <div>{icon}</div>
            </div>

            <div className="text-center my-label-sm hover:underline">
              {heading}
            </div>
          </Component>
        </div>
      )
    }

    return (
      <IconCard
        as={as}
        ref={ref}
        icon={icon}
        heading={heading}
        description={description}
        isLoading={isLoading}
        {...props}
      />
    )
  },
)
