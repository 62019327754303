import { CartValidator, getCartValidation } from 'components/Cart/validation'
import { OrderingTypeVenueValidator } from 'components/Venue/utils/venueUtils'
import { useCurrentSheet } from 'hooks/useCurrentSheet'
import { GuestVenue } from 'lib/gql'
import { SheetSlug } from 'lib/routes'
import omit from 'lodash/omit'
import { useRouter } from 'next/router'
import { useCallback, useContext } from 'react'
import { IntlShape } from 'react-intl'
import { CustomerStoreContext } from 'stores/CustomerStore'

type UserVenueValidator = {
  cart?: CartValidator | null
  venue: Pick<GuestVenue, 'skipTableNumberModal'> & OrderingTypeVenueValidator
  asapEnabled?: boolean
}

/*
  To be used for pushing/removing sheet state only. Can also be used safely on page load
  without causing a full redirect or dropping query params like table number etc. 
  This is useful now that VenuePage can be rendered from a few different file paths.
*/
export const useSheetToggle = () => {
  const router = useRouter()
  const query = router.query
  return useCallback(
    ({ sheet }: { sheet?: SheetSlug | null }) =>
      router.push(
        {
          pathname: router.pathname,
          query: {
            ...omit(query, 'sheet'),
            ...(sheet ? { sheet: sheet } : {}),
          },
        },
        undefined,
        { shallow: true },
      ),
    [router, query],
  )
}

export const sheetPaths = ({
  cart,
  asapEnabled,
}: {
  cart: CartValidator
  asapEnabled?: boolean
}) => {
  const {
    shouldSelectOrderingWindow,
    shouldAddAddress,
    shouldAddTableArea,
    shouldAddTableNumber,
    venueNotTakingOrders,
  } = getCartValidation({
    cartValidationErrorsV2: cart?.cartValidationErrorsV2,
    total: cart?.total,
  })

  const editPaths: SheetSlug[] = []
  const addPaths: SheetSlug[] = []

  // No ordering or ordering is not available
  if (!venueNotTakingOrders) {
    if (shouldSelectOrderingWindow) {
      addPaths.push('order-window')
    } else if (cart?.orderingWindowStartDate) {
      editPaths.push('order-window')
    } else if (asapEnabled) {
      editPaths.push('order-window')
    }

    if (shouldAddAddress) {
      addPaths.push('address')
    } else if (cart?.currentLocation) {
      editPaths.push('address')
    }

    if (shouldAddTableArea || shouldAddTableNumber) {
      addPaths.push('table-number')
    } else if (cart?.tableNumber) {
      editPaths.push('table-number')
    }
  }

  return {
    editPaths,
    addPaths,
  }
}

export const findNextSheet = (
  current: SheetSlug | null,
  path: SheetSlug[],
): SheetSlug | null => {
  if (!current) {
    return path[0]
  }

  const index = path.indexOf(current)

  if (index === -1) {
    return path[0]
  }

  if (index === path.length - 1) {
    return null
  }

  return path[index + 1]
}

export const getSelectTextFromSheet = (
  sheet: SheetSlug | null,
  tablePromptText: string | undefined,
  { formatMessage }: IntlShape,
) => {
  switch (sheet) {
    case 'address':
      return formatMessage({ defaultMessage: 'Select address', id: 'It0qSm' })
    case 'table-number':
      return (
        tablePromptText ||
        formatMessage({
          defaultMessage: 'Select table number',
          id: 's7CkJR',
        })
      )
    case 'order-window':
      return formatMessage({
        defaultMessage: 'Select ordering window',
        id: 'namfZU',
      })
  }

  return formatMessage({ defaultMessage: 'Select', id: 'kQAf2d' })
}

export const useGetNextSheet = ({ cart, asapEnabled }: UserVenueValidator) => {
  const currentSheet = useCurrentSheet()
  const { addPaths, editPaths } = cart
    ? sheetPaths({
        cart,
        asapEnabled,
      })
    : { addPaths: [], editPaths: [] }

  let nextSheet

  if (addPaths.length > 0) {
    nextSheet = findNextSheet(currentSheet, addPaths)
  } else if (editPaths.length > 0) {
    nextSheet = findNextSheet(currentSheet, editPaths)
  }

  return { addPaths, editPaths, nextSheet }
}

// should called once after cart/page load
export const useCheckIfCartRequiresSheet = ({
  venue,
  ...rest
}: UserVenueValidator) => {
  const currentSheet = useCurrentSheet()
  const sheetToggle = useSheetToggle()
  const { nextSheet, addPaths } = useGetNextSheet({ venue, ...rest })
  const { viewedOrderingTypeModal } = useContext(CustomerStoreContext)

  return useCallback(async () => {
    if (currentSheet || !nextSheet || addPaths.length === 0) {
      return
    }
    /*
      If a user has seen the ordering type modal/ordering window modal then we assume they are making an action and
      so should be shown the table number modal. This does mean that if the venue has these setting applied:
       - "Show ordering type picker on landing" = true
       - "Show table number modal on landing" = false

    We will still show them the table number modal after they see the ordering type modal even though the user hasn't
    manually opened any modals.
    */
    if (
      !viewedOrderingTypeModal &&
      nextSheet === 'table-number' &&
      venue.skipTableNumberModal
    ) {
      return
    }
    return await sheetToggle({
      sheet: nextSheet,
    })
  }, [
    nextSheet,
    currentSheet,
    venue,
    addPaths,
    sheetToggle,
    viewedOrderingTypeModal,
  ])
}
