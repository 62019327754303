import { SVGNoFilterResults } from '@mr-yum/frontend-ui'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MenuStoreContext } from 'stores/MenuStore'

const FakeLink = observer(({ children }: React.PropsWithChildren) => {
  const { setCategorySearch } = useContext(MenuStoreContext)

  return (
    <span className="cursor-pointer underline">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault()
          setCategorySearch('')
        }}
      >
        {children}
      </a>
    </span>
  )
})

FakeLink.displayName = 'FakeLink'

export const NoFilteredCategories = () => {
  const intl = useIntl()

  return (
    <div
      className="relative mb-9 flex w-full flex-col items-center justify-center overflow-hidden rounded"
      aria-label={intl.formatMessage({
        defaultMessage: 'No menu categories found',
        id: '0lPLGC',
      })}
    >
      <SVGNoFilterResults width="96px" height="94px" />

      <h2 className="mb-1 mt-2 text-foreground my-label-md">
        No results found
      </h2>

      <FakeLink>
        <FormattedMessage
          defaultMessage="Try clearing your search."
          id="WViT2/"
        />
      </FakeLink>
    </div>
  )
}
