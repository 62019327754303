import { NavBar } from 'components/Common/NavBar'
import { Footer } from 'components/Menu/Footer'
import { Text } from 'components/Skeleton/Text'
import { MenuCategoriesLoader } from 'components/Venue/Categories/MenuCategoriesLoader'
import React from 'react'

interface Props {
  categoriesCount?: number
}

export const VenuePageLoader = ({ categoriesCount }: Props) => (
  <>
    <NavBar />

    <div
      className="flex min-h-screen w-full flex-wrap pt-[55px]"
      data-testid="venue-page-loader"
    >
      <div className="relative flex-1">
        <div className="mx-auto flex h-full w-full flex-col">
          <div className="flex-grow">
            <div className="block md:hidden">
              <header className="relative h-0 w-full pt-[35%] skeleton">
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <Text chars={12} />
                </div>
              </header>
            </div>

            <div className="relative hidden bg-surface md:block">
              <div className="flex w-full items-center justify-center py-8">
                <div className="relative h-[124px] w-[124px]"></div>
              </div>
            </div>

            <div className="flex min-h-[58px] items-center justify-between border-b bg-surface px-4 py-3 md:px-8 lg:px-12">
              <div className="hidden md:block">
                <Text chars={12} />
              </div>

              <Text chars={12} />
            </div>

            <div className="px-4 pt-5 md:px-8 md:pt-8 lg:px-12">
              <div className="grid grid-cols-1 gap-x-6 sm:grid-cols-[repeat(auto-fill,minmax(320px,1fr))]">
                <MenuCategoriesLoader count={categoriesCount} />
              </div>

              <hr />
            </div>
          </div>

          <Footer />
        </div>
      </div>

      <div className="hidden flex-1 md:block">
        <div className="sticky top-[55px] z-[1] h-[calc(100vh-55px)]">
          <div className="h-full w-full skeleton" />
          <div className="absolute left-0 top-0 h-full w-[1px] bg-black/[0.05]"></div>
        </div>
      </div>
    </div>
  </>
)
