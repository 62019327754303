import { Button } from '@mr-yum/frontend-ui'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  programName: string
  authUrl: string
  isLoading: boolean
}

export const AuraOAuthCTA = ({ programName, authUrl, isLoading }: Props) => {
  return (
    <div
      className="rounded-2xl bg-surface-subtle p-5 text-foreground"
      data-testid="aura-oauth-cta"
    >
      <h3 className="mb-2 my-label-md">
        <FormattedMessage
          defaultMessage="Log in to {programName}"
          id="0OGZFJ"
          values={{ programName }}
        />
      </h3>

      <p className="mb-3 my-body-md">
        <FormattedMessage
          defaultMessage="Earn and redeem points when you place an order on me&u."
          id="DL6+IY"
        />
      </p>

      <Button
        size="sm"
        className="!py-3"
        as="a"
        href={authUrl}
        fullWidth
        isLoading={isLoading}
      >
        <FormattedMessage defaultMessage="Log in" id="odXlk8" />
      </Button>
    </div>
  )
}
