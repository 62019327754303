import { AddressSheet } from 'components/Venue/Sheets/AddressSheet'
import { OrderingTypeSheet } from 'components/Venue/Sheets/OrderingTypeSheet'
import { OrderingWindowSheet } from 'components/Venue/Sheets/OrderingWindowSheet'
import { TableNumberSheet } from 'components/Venue/Sheets/TableNumberSheet'
import {
  useCheckIfCartRequiresSheet,
  useSheetToggle,
} from 'components/Venue/utils/sheetUtils'
import { useCurrentSheet } from 'hooks/useCurrentSheet'
import { useEffectOnce } from 'hooks/useEffectOnce'
import { CartLandingQuery, GuestVenue, VenueLandingQuery } from 'lib/gql'
import { SheetSlug } from 'lib/routes'
import { NextRouter, useRouter } from 'next/router'
import React, { useCallback } from 'react'

import { ResponsiveModal } from '../../ResponsiveModal'
import { OrderingTypeVenueValidator } from '../utils/venueUtils'

export interface SheetsProps {
  venue: VenueLandingQuery['guestVenue']
  cart: CartLandingQuery['getCart']
  cartFetching: boolean
  cartStale: boolean
  cartError?: Error | null
  orderingTypePrompt?: boolean
}

export interface SheetChildProps {
  sheet?: SheetSlug | null
  showBack?: boolean
  isCloseable?: boolean
  venue: NonNullable<VenueLandingQuery['guestVenue']>
  cart: CartLandingQuery['getCart']
  cartFetching: boolean
  cartStale: boolean
  cartError?: Error | null
  router: NextRouter
  onClose: () => void
}

/*
  Once the cart is loaded, we want to prompt the user to enter required details.
  If they close a modal we shoudn't bug them again until they load the venue home page again.
*/
export const UserVenueSetup = ({
  venue,
  cart,
}: {
  venue: Pick<GuestVenue, 'skipTableNumberModal'> & OrderingTypeVenueValidator
  cart: NonNullable<CartLandingQuery['getCart']>
}) => {
  const checkIfCartRequiresSheet = useCheckIfCartRequiresSheet({
    venue,
    cart,
  })
  useEffectOnce(() => {
    void checkIfCartRequiresSheet()
  })

  return null
}

export const Sheets = ({
  venue,
  cart,
  cartFetching,
  cartStale,
  cartError,
  orderingTypePrompt,
}: SheetsProps) => {
  const router = useRouter()
  const sheet = useCurrentSheet()
  const sheetToggle = useSheetToggle()

  useEffectOnce(() => {
    if (orderingTypePrompt) {
      void sheetToggle({ sheet: 'ordering-type' })
    }
  })

  /*
    If user closes modal we want to just remove the sheet and not hound
    them with another modal after.
  */
  const onClose = useCallback(() => {
    void sheetToggle({ sheet: null })
  }, [sheetToggle])

  const sheetProps: Omit<SheetChildProps, 'venue'> = {
    sheet,
    cart,
    cartFetching,
    cartStale,
    router,
    onClose,
    isCloseable: true,
    cartError,
  }

  return (
    <>
      {!cartFetching && !cartStale && !cartError && venue && cart && (
        <UserVenueSetup venue={venue} cart={cart} />
      )}
      <ResponsiveModal onClose={onClose}>
        {venue && sheet && (
          <>
            {sheet === 'ordering-type' && (
              <OrderingTypeSheet
                showBack={false}
                {...sheetProps}
                venue={venue}
              />
            )}
            {sheet === 'order-window' && (
              <OrderingWindowSheet {...sheetProps} venue={venue} />
            )}
            {sheet === 'table-number' && (
              <TableNumberSheet {...sheetProps} venue={venue} />
            )}
            {sheet === 'address' && (
              <AddressSheet {...sheetProps} venue={venue} />
            )}
          </>
        )}
      </ResponsiveModal>
    </>
  )
}
