import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { routes } from 'lib/routes'
import React from 'react'

import { SearchResultsToDisplay } from '../utils/searchUtils'

export const CategorySearchDisplay = ({
  vendor,
}: {
  vendor: SearchResultsToDisplay
}) => {
  const { orderingTypeSlug } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  return (
    <RouteLink
      route={routes.menuCategory}
      pathData={{
        venueSlug,
        orderingTypeSlug,
        category: vendor.categorySlug,
      }}
    >
      <a
        data-testid="ListItem"
        className="flex w-full items-center bg-interactive-subtle-surface py-3 hover:bg-interactive-subtle-surface-hover"
      >
        <div className="space-between flex flex-grow pl-0.5">
          <p className="my-body-md-bold">{vendor.categoryName}</p>
        </div>
        <div className="flex flex-shrink-0 items-center justify-items-end">
          <p className="text-right text-action-text-primary-foreground my-body-sm">
            View more
          </p>
        </div>
      </a>
    </RouteLink>
  )
}
