import { TransformType } from '@mr-yum/cdn-image'
import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { Alert, Logo, WarningFilledIcon } from '@mr-yum/frontend-ui'
import { useFlag } from '@unleash/proxy-client-react'
import { FLAG_GROUP_ORDERING_V2 } from 'common/constants'
import { AnotherRoundModal } from 'components/AnotherRoundModal/AnotherRoundModal'
import { ClientOnly } from 'components/Common/ClientOnly'
import { NotFound } from 'components/Common/NotFound'
import { RewardMembershipCTA } from 'components/Confirmation/RewardMembershipCTA'
import { Footer } from 'components/Menu/Footer'
import { getCartItemCount } from 'components/Menu/utils'
import { ViewOrderButton } from 'components/Menu/ViewOrderButton'
import { Meta } from 'components/Meta/Meta'
import { Image } from 'components/Shared/Image'
import { OrderingUnavailableNotification } from 'components/TableOff/OrderingUnavailableNotification'
import { ConfigureOrderingType } from 'components/Venue/Blocks/ConfigureOrderingType'
import { OrderingTypeButton } from 'components/Venue/Blocks/OrderingTypeButton'
import { VenueClosedBanner } from 'components/Venue/Blocks/VenueClosedBanner'
import { VenueCTAButton } from 'components/Venue/Blocks/VenueCTAButton'
import { VenueMessage } from 'components/Venue/Blocks/VenueMessage'
import { Sheets } from 'components/Venue/Sheets/Sheets'
import {
  findSettingsByOrderingType,
  isOrderingTypeAvailable,
} from 'components/Venue/utils/venueUtils'
import { VenueCategories } from 'components/Venue/VenueCategories'
import { VenueHeaderBar } from 'components/Venue/VenueHeaderBar'
import { VenuePageLoader } from 'components/Venue/VenuePageLoader'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { useCartLanding } from 'hooks/useCart'
import { PartnerLoginContext } from 'hooks/usePartnerLogin'
import {
  OrderingType,
  useCurrentUserQuery,
  useCurrentUserTabsQuery,
  useListOpenGroupOrdersForVenueQuery,
  useVenueLandingAdditionalQuery,
  useVenueLandingQuery,
  useVenueListMembershipsQuery,
} from 'lib/gql'
import { routes } from 'lib/routes'
import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MenuStoreContext } from 'stores/MenuStore'
import { useDebounce } from 'usehooks-ts'

import { ActionBlocks } from './Blocks/ActionBlocks'
import { ActionBlocksLoader } from './Blocks/ActionBlocksLoader'
import { CategorySearchV2 } from './MvoSearch/CategorySearch'
import { DisplayCategorySearchResults } from './MvoSearch/DisplayCategorySearchResults'

export type VenuePageProps = {
  orderingTypePrompt?: boolean
  showThePassUpdateRequiredNotice?: boolean
}

export const VenuePage = observer(
  ({
    orderingTypePrompt = false,
    showThePassUpdateRequiredNotice = false,
  }: VenuePageProps) => {
    const { orderingType, orderingTypeSlug } = useOrderingTypeContext()
    const { venueSlug } = useVenueContext()
    const intl = useIntl()

    const { categorySearch } = useContext(MenuStoreContext)
    const { partnerError } = useContext(PartnerLoginContext)
    const isGroupOrderingV2Enabled = useFlag(FLAG_GROUP_ORDERING_V2)

    const [{ data: venueData, error: venueError, fetching: venueFetching }] =
      useVenueLandingQuery({
        variables: {
          venueSlug,
        },
      })

    const [{ data: userData, fetching: userDataFetching }] =
      useCurrentUserQuery({
        pause: isServer,
      })

    const venue = venueData?.guestVenue

    const {
      cart,
      stale: cartStale,
      fetching: cartFetching,
      error: cartError,
    } = useCartLanding({
      venueSlug,
      orderingType,
      pause: !venue,
      requestPolicy: 'cache-and-network',
    })

    const settings = findSettingsByOrderingType({
      venue,
      orderingType,
    })
    const mobileBanner = venue?.mobileBanner ?? venue?.banner
    const landingText = settings?.landingText
    const isVisualMenu = orderingType === OrderingType.Menu

    // View order button
    const itemCount = getCartItemCount(cart)
    const showViewOrder =
      itemCount > 0 &&
      venue?.ordering &&
      isOrderingTypeAvailable(venue, orderingType)

    const tableIsOff = useMemo(() => {
      if (!cart) {
        return false
      }

      return cart.cartValidationErrorsV2.some(
        (value) => value.type === 'TABLE_NUMBER_ALL_OFF',
      )
    }, [cart])

    const debouncedCategorySearch = useDebounce(categorySearch)

    const [{ data: additionalData, fetching: additionalDataFetching }] =
      useVenueLandingAdditionalQuery({
        pause: isServer || !venue?.id,
        variables: {
          venueSlug,
          orderingType,
        },
        requestPolicy: 'cache-first',
      })

    const [
      {
        data: currentUserOpenGroupOrders,
        fetching: currentUserGroupOrdersFetching,
      },
    ] = useListOpenGroupOrdersForVenueQuery({
      pause: isServer || !userData?.currentUser || !venue,
      variables: {
        venueId: venue?.id!,
      },
      requestPolicy: 'cache-and-network',
    })

    const [
      { data: venueMembershipData, fetching: venueMembershipDataFetching },
    ] = useVenueListMembershipsQuery({
      pause: isServer || !venue?.id || userDataFetching,
      variables: {
        venueId: venue?.id!,
        guestId: userData?.currentUser?.id,
      },
      requestPolicy: 'cache-and-network',
    })

    // TODO: Remove this when SocialTab legacy flow is removed.
    const [{ data: currentUserTabsData, fetching: fetchingCurrentUserTabs }] =
      useCurrentUserTabsQuery({
        pause: isServer,
        variables: { venueSlug },
        requestPolicy: 'cache-and-network',
      })

    if (venueError || (!venueFetching && !venue)) {
      return (
        <>
          <NotFound
            message={intl.formatMessage(
              {
                defaultMessage: 'Venue {venueSlug} not found',
                id: '2R567s',
              },
              { venueSlug },
            )}
            error={venueError}
          />
        </>
      )
    }

    return (
      <>
        {!venue ? (
          <VenuePageLoader categoriesCount={6} />
        ) : (
          <>
            <Meta
              title={`${venue.name} - Explore our menu with photos of every item. Order & pay online.`}
              description={`Explore the mobile menu at ${venue.name}. Order & pay online with me&u.`}
              openGraph={{
                title: `${venue.name} - Explore our menu with photos of every item. Order & pay online.`,
                description: `Explore the mobile menu at ${venue.name}. Order & pay online with me&u.`,
              }}
            />

            <VenueHeaderBar
              venueName={venue.name}
              venueSlug={venueSlug}
              orderingType={orderingType}
            />

            <div className="flex min-h-screen w-full flex-col flex-wrap pt-[55px] md:flex-row">
              <div className="md:max-w-1/2 relative w-full flex-1">
                <div className="mx-auto flex h-full w-full flex-col">
                  <div className="flex-grow">
                    <div className="overflow-hidden">
                      {tableIsOff && <OrderingUnavailableNotification />}
                      <VenueClosedBanner
                        venue={venue}
                        orderingType={orderingType}
                      />
                    </div>

                    <div className="block md:hidden">
                      <header className="relative h-0 w-full bg-surface-subtle pt-[35%]">
                        {mobileBanner && (
                          <div className="absolute inset-0">
                            <Image
                              image={mobileBanner}
                              transform={TransformType.MOBILE_BANNER}
                              className="!inset-0"
                              alt=""
                              layout="fill"
                              objectFit="cover"
                              sizes="390px"
                              priority
                            />
                          </div>
                        )}

                        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                          <OrderingTypeButton venue={venue} />
                        </div>
                      </header>
                    </div>

                    <div className="border-b bg-surface-ui-background text-foreground">
                      <div className="relative hidden md:block">
                        <div className="flex w-full items-center justify-center py-8">
                          <div className="relative h-[124px] w-[124px]">
                            {venue.logo ? (
                              <Image
                                image={venue.logo}
                                className="!object-contain"
                                objectFit="contain"
                                layout="fill"
                              />
                            ) : (
                              <Logo
                                width={124}
                                height={124}
                                className="relative inline-block"
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      {venue.multiVendorEnabled && <CategorySearchV2 />}

                      <div className="flex items-center justify-between">
                        <div className="hidden max-w-[16vw] py-3 pl-4 md:block md:pl-8 lg:pl-12">
                          <OrderingTypeButton venue={venue} />
                        </div>

                        {!isVisualMenu && !cartError && (
                          <ConfigureOrderingType
                            orderingTypeSlug={orderingTypeSlug}
                            orderingType={orderingType}
                            venue={venue}
                            venueSlug={venueSlug}
                            cart={cart}
                            tableShortText={settings?.tableShortText}
                            tablePromptText={settings?.tablePromptText}
                            isLoading={cartFetching}
                            isTableTurnedOff={tableIsOff}
                          />
                        )}
                      </div>
                    </div>

                    {showThePassUpdateRequiredNotice && (
                      <div className="px-4 pt-4 lg:px-12 lg:pt-8">
                        <Alert
                          variant="warning"
                          icon={<WarningFilledIcon />}
                          title="Don’t miss out on rewards"
                          fullWidth
                        >
                          <p className="mb-2">
                            To earn points when you order, download the latest
                            version of The Pass app
                          </p>

                          <a
                            className="my-label-sm"
                            href="https://l.ead.me/AVCApp"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Install now
                          </a>
                        </Alert>
                      </div>
                    )}

                    <div className="space-y-4 px-4 py-5 text-foreground md:px-8 md:py-8 lg:px-12">
                      {partnerError && (
                        <Alert
                          variant="warning"
                          icon={<WarningFilledIcon />}
                          data-testid="partner-error"
                          title="Your cart has expired! Please click the Home tab and select Order at Table to start again."
                          fullWidth
                        />
                      )}
                      <ClientOnly>
                        {debouncedCategorySearch === '' && (
                          <>
                            {(additionalDataFetching ||
                              fetchingCurrentUserTabs ||
                              currentUserGroupOrdersFetching) && (
                              <ActionBlocksLoader />
                            )}
                            {additionalData &&
                              (currentUserTabsData ||
                                currentUserOpenGroupOrders) && (
                                <ActionBlocks
                                  venueSlug={venueSlug}
                                  venue={venue}
                                  orderingType={orderingType}
                                  tabs={
                                    (isGroupOrderingV2Enabled
                                      ? currentUserOpenGroupOrders?.listBills
                                          .items
                                      : currentUserTabsData?.currentUser
                                          ?.managedTabs) ?? []
                                  }
                                  additionalInfoData={additionalData}
                                />
                              )}
                          </>
                        )}

                        {!venueMembershipDataFetching &&
                          !userDataFetching &&
                          venueMembershipData?.listVenueMemberships?.map(
                            (program) => {
                              return (
                                program?.configuration?.cta?.['link'] &&
                                !program.membership.id && (
                                  <RewardMembershipCTA
                                    key={program.id}
                                    venueName={venue.name}
                                    program={program}
                                    cta="link"
                                    onClick={function (): void {}}
                                    href={routes.rewardLinkMembership.toPath!({
                                      venueSlug,
                                      programId: program.id,
                                    })}
                                  />
                                )
                              )
                            },
                          )}
                      </ClientOnly>

                      {(isVisualMenu || landingText) && (
                        <div className="space-y-2">
                          {isVisualMenu && (
                            <h2 className="my-heading-md">
                              <FormattedMessage
                                defaultMessage="View menu"
                                id="ZAUJRl"
                              />
                            </h2>
                          )}

                          {landingText && (
                            <VenueMessage message={landingText} />
                          )}
                        </div>
                      )}

                      <DisplayCategorySearchResults
                        cart={cart}
                        debouncedCategorySearch={debouncedCategorySearch}
                      />

                      <VenueCategories
                        multiVendorEnabled={venue.multiVendorEnabled}
                        showCategoryListViewOnLandingPage={
                          venue.showCategoryListViewOnLandingPage
                        }
                        cart={cart}
                        cartStale={cartStale}
                        cartFetching={cartFetching}
                        orderingTypes={venue.orderingTypes}
                        debouncedCategorySearch={debouncedCategorySearch}
                      />

                      <VenueCTAButton
                        text={venue.landingCallToActionText}
                        url={venue.landingCallToActionUrl}
                      />
                    </div>
                  </div>

                  <Footer hasFloatingButton={showViewOrder} />
                </div>
              </div>

              <div className="md:max-w-1/2 hidden flex-1 md:block">
                <div className="sticky top-[55px] z-[1] h-[calc(100vh-55px)]">
                  <div className="relative h-full w-full bg-surface-subtle">
                    {venue.banner && (
                      <Image
                        image={venue.banner}
                        transform={TransformType.BANNER}
                        layout="fill"
                        objectFit="cover"
                        sizes="50vw"
                      />
                    )}

                    <div className="absolute left-0 top-0 h-full w-[1px] bg-black/[0.05]"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <Sheets
          venue={venue}
          cart={cart}
          cartFetching={cartFetching}
          cartStale={cartStale}
          cartError={cartError}
          orderingTypePrompt={orderingTypePrompt}
        />

        {showViewOrder && (
          <ViewOrderButton
            venueSlug={venueSlug}
            orderingTypeSlug={orderingTypeSlug}
            itemCount={itemCount}
          />
        )}

        <AnotherRoundModal
          isCartEmpty={cart ? itemCount === 0 : false}
          anotherRoundCartItems={additionalData?.getAnotherRoundCartItems}
        />
      </>
    )
  },
)

VenuePage.displayName = 'VenuePage'
