import dynamic from 'next/dynamic'
import React from 'react'

import { ImageItemSkeleton } from './ImageItemSkeleton'
import { ListItemSkeleton } from './ListItemSkeleton'

export const ImageItem = dynamic(
  async () => (await import('components/MenuItem/ImageItem')).ImageItem,
  { loading: () => <ImageItemSkeleton /> },
)

export const ListItem = dynamic(
  async () => (await import('components/MenuItem/ListItem')).ListItem,
  { loading: () => <ListItemSkeleton /> },
)
