import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon, Radio } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import { useLogger } from 'hooks/useLogger'
import { observer } from 'mobx-react-lite'
import React, { Fragment, useCallback, useContext } from 'react'
import { MenuStoreContext } from 'stores/MenuStore'

import { Language, LanguageItems } from './utils'

interface Props {
  maxWidth?: number
}

export const LanguagePicker = observer(({ maxWidth = 468 }: Props) => {
  const { logEvent } = useLogger()

  const { language, setLanguage } = useContext(MenuStoreContext)

  const activeLanguage =
    LanguageItems.find((item) => language === item.value) || LanguageItems[0]

  const handleClick = useCallback(() => {
    logEvent('Clicks on language picker')
  }, [logEvent])

  const handleChange = useCallback(
    (item: Language) => {
      const previousLanguage = language
      setLanguage(item.value)

      logEvent('Language changed', {
        nextLanguage: item.value,
        previousLanguage,
      })
    },
    [language, logEvent, setLanguage],
  )

  return (
    <Listbox
      value={activeLanguage}
      onChange={handleChange}
      data-testid="language-picker"
    >
      <div className="relative z-10 mt-1">
        <Listbox.Button
          className="flex items-center rounded border px-4 py-2 hover:bg-interactive-surface-hover"
          onClick={handleClick}
        >
          <span className="mr-3">{activeLanguage.label}</span>
          <ChevronDownIcon />
        </Listbox.Button>

        <Transition
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={`absolute right-0 mt-3 max-h-[calc(100vh-100px)] w-[calc(100vw-32px)] overflow-auto rounded-md border bg-surface-bright py-1 ring-1 ring-black ring-opacity-5 focus:outline-none`}
            style={{ maxWidth }}
          >
            {LanguageItems.map((item) => (
              <Listbox.Option key={item.label} value={item} as={Fragment}>
                {({ active, selected }) => (
                  <li
                    className={cn(
                      'bg-interactive-surfacerelative flex cursor-pointer select-none items-center justify-between px-4 py-3',
                      {
                        'bg-interactive-surface-hover': active,
                      },
                    )}
                  >
                    <div>{item.label}</div>

                    <Radio type="radio" checked={selected} readOnly />
                  </li>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
})

LanguagePicker.displayName = 'LanguagePicker'
