import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import {
  Button,
  GiftOutlineIcon,
  MoreHorizontalIcon,
  ReceiptOutlineIcon,
  UserCircleOutlineIcon,
} from '@mr-yum/frontend-ui'
import { Sheet } from 'components/Common/Sheet'
import { isEonxEnvironment } from 'components/EonX/utils'
import { ResponsiveModal } from 'components/ResponsiveModal'
import { AccountExternalRewardsButton } from 'components/Rewards/AccountExternalRewardsButton'
import { AuraOAuthCTA } from 'components/Rewards/AuraOAuthCTA'
import { useAuraOAuth } from 'components/Rewards/hooks/useAuraOAuth'
import { useDisclosure } from 'hooks/useDisclosure'
import { useLogger } from 'hooks/useLogger'
import { config } from 'lib/config'
import { CurrentUserQuery } from 'lib/gql'
import { routes } from 'lib/routes'
import { useRouter } from 'next/router'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { AccountLink } from './AccountLink'
import { LoginCTA } from './LogInCTA'

interface Props {
  venueSlug: string
  currentUser: CurrentUserQuery['currentUser']
}

export const GuestProfileBody = ({ currentUser, venueSlug }: Props) => {
  const intl = useIntl()
  const {
    isEnabled: isAuraOAuthEnabled,
    url: auraAuthUrl,
    programName: auraProgramName,
    isLoggedIn: isLoggedIntoAura,
    fetching: isFetchingAura,
    error: auraError,
    logout: auraLogout,
  } = useAuraOAuth({ venueSlug })
  const { setOpen, isOpen, setClosed } = useDisclosure(false)
  const { logEvent } = useLogger()
  const { asPath } = useRouter()
  const loginRoute =
    config.environment === 'production' ? routes.login : routes.loginSsr

  const handleAuraLogout = () => {
    auraLogout()
    setClosed()
  }

  return (
    <>
      <main data-testid="guest-profile-body">
        <h3 className="mb-4 my-heading-lg">
          {currentUser ? (
            <FormattedMessage defaultMessage="Your account" id="95pnlV" />
          ) : (
            <FormattedMessage defaultMessage="Account" id="TwyMau" />
          )}
        </h3>

        <AccountLink
          icon={<UserCircleOutlineIcon />}
          title={intl.formatMessage({
            defaultMessage: 'Edit account details',
            id: 'AQGwgI',
          })}
          route={routes.editProfile}
          pathData={{ venueSlug }}
        />

        {!isEonxEnvironment() && (
          <AccountLink
            icon={<ReceiptOutlineIcon />}
            title={intl.formatMessage({
              defaultMessage: 'Your orders & receipts',
              id: 'j+c9fD',
            })}
            route={routes.history}
            pathData={{ venueSlug, goBack: true }}
          />
        )}

        {isAuraOAuthEnabled && currentUser && isLoggedIntoAura && (
          <AccountExternalRewardsButton
            icon={<GiftOutlineIcon />}
            title={auraProgramName}
            onClick={setOpen}
          >
            <MoreHorizontalIcon />
          </AccountExternalRewardsButton>
        )}
      </main>

      <div className="flex-1"></div>

      <div className="mt-4">
        {!currentUser && <LoginCTA venueSlug={venueSlug} />}

        {isAuraOAuthEnabled &&
          currentUser &&
          !isLoggedIntoAura &&
          !auraError && (
            <AuraOAuthCTA
              authUrl={auraAuthUrl || '#'}
              programName={auraProgramName || 'rewards'}
              isLoading={isFetchingAura}
            />
          )}

        <footer className="flex justify-center pb-6 pt-4 text-foreground my-body-sm">
          <a
            href="https://www.mryum.com/privacy-policy"
            target="_blank"
            className="px-4 underline"
            rel="noreferrer"
          >
            <FormattedMessage defaultMessage="Privacy" id="cXBJ7U" />
          </a>

          <div>&middot;</div>

          <a
            href="https://help.meandu.com"
            target="_blank"
            className="px-4 underline"
            rel="noreferrer"
          >
            <FormattedMessage defaultMessage="Support" id="HqRNN8" />
          </a>

          {!currentUser && (
            <>
              <div>&middot;</div>

              <RouteLink
                route={loginRoute}
                pathData={{ venueSlug, returnTo: asPath }}
              >
                <a
                  className="px-4 underline"
                  data-testid="login-bottom-link"
                  onClick={() =>
                    logEvent(
                      'Clicked on log in bottom link on guest profile page',
                    )
                  }
                >
                  <FormattedMessage defaultMessage="Log in" id="odXlk8" />
                </a>
              </RouteLink>
            </>
          )}
        </footer>
      </div>
      {isOpen && (
        <ResponsiveModal onClose={setClosed}>
          <Sheet
            showBack={false}
            isCloseable
            onClose={setClosed}
            title={<FormattedMessage defaultMessage="Logout" id="C81/uG" />}
          >
            <div className="p-4">
              <div className="pb-9 text-center">
                <p>
                  <FormattedMessage
                    defaultMessage="By logging out you will no longer have access to redeem points, claim offers or get rewards."
                    id="Y9at2p"
                  />
                </p>
              </div>

              <Button
                data-testid="logout-confirm"
                type="submit"
                variant="primary"
                size="lg"
                fullWidth
                aria-label={intl.formatMessage({
                  defaultMessage: 'Rewards logout confirm',
                  id: 'wJU14S',
                })}
                onClick={handleAuraLogout}
              >
                <FormattedMessage defaultMessage="Logout" id="C81/uG" />
              </Button>
            </div>
          </Sheet>
        </ResponsiveModal>
      )}
    </>
  )
}
