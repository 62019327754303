import { CloseIcon, IconButton } from '@mr-yum/frontend-ui'
import { useCurrentUser } from 'hooks/useCurrentUser'
import React from 'react'
import { useIntl } from 'react-intl'

import { LanguagePicker } from '../Lang/LanguagePicker'
import { GuestProfileBody } from './GuestProfileBody'
import { GuestProfileLoader } from './GuestProfileLoader'

interface Props {
  venueSlug: string
  handleClose: () => void
}

export const GuestProfileMenu = ({ venueSlug, handleClose }: Props) => {
  const intl = useIntl()
  const { currentUser, fetchingCurrentUser } = useCurrentUser()

  return (
    <div className="relative flex h-full w-full max-w-[calc(100vw-48px)] flex-col bg-surface text-foreground md:min-w-[400px]">
      <IconButton
        className="absolute left-0 top-0"
        onClick={handleClose}
        aria-label={intl.formatMessage({
          defaultMessage: 'Close menu',
          id: 'dg8ZEb',
        })}
      >
        <CloseIcon />
      </IconButton>

      <div className="mx-auto flex h-full w-full flex-col px-4 md:px-7">
        <header className="flex justify-end pb-6 pt-14">
          <LanguagePicker maxWidth={344} />
        </header>

        {fetchingCurrentUser ? (
          <GuestProfileLoader />
        ) : (
          <GuestProfileBody venueSlug={venueSlug} currentUser={currentUser} />
        )}
      </div>
    </div>
  )
}
