import React from 'react'

export const ActionBlocksLoader = () => {
  return (
    <div
      className="relative mb-4 h-[74px] w-full rounded skeleton"
      data-testid="action-block-loader"
    />
  )
}
