import { FLAG_EONX_PAYMENT_V2 } from 'common/constants'
import { updateEonxEnvironment } from 'components/EonX/utils'
import { ORIGIN_COOKIE } from 'components/PaymentV2/constants'
import { useVenueContext } from 'contexts/VenueOrderContext'
import Cookies from 'js-cookie'
import {
  Partner,
  useEonxCheckInGuestMutation,
  useEonxLoginWithPartnerMutation,
  useResetCartMutation,
} from 'lib/gql'
import { getCleanPath } from 'lib/utils'
import Router, { useRouter } from 'next/router'
import React, { createContext, ReactNode, useCallback, useState } from 'react'
import { CombinedError } from 'urql'

import { useEffectOnce } from './useEffectOnce'
import { useFeatureFlag } from './useFeatureFlag'
import { useLogger } from './useLogger'

type PartnerLoginResult = {
  partnerResolved: boolean
  partnerError?: CombinedError
}

/*
  Major Tech debt because we don't have SSR feature flags yet..
  We need to fetch a feature flag before we can login to Eonx on Home Page load which happens client-side
  So this means the loader at the start will be a little slower
  But we don't want to show the client-side loader for non Eonx users
  Also if there's a flagError we want to just fallback to legacy login
*/
export const useLegacyLoginFallback = () => {
  const { query } = useRouter()
  const {
    ready,
    value,
    error: flagError,
  } = useFeatureFlag(FLAG_EONX_PAYMENT_V2)

  const showLoader = !query.eonxCheckInId || flagError ? false : !ready
  const legacyLogin = flagError ? true : !value

  return { showLoader, legacyLogin }
}

export const PartnerLoginContext = createContext<PartnerLoginResult>({
  partnerResolved: false,
})

export const WithPartnerLogin = ({
  children,
  ...props
}: {
  children: ReactNode
} & PartnerLoginProps) => {
  const partnerLoginResult = usePartnerLogin(props)

  return (
    <PartnerLoginContext.Provider value={partnerLoginResult}>
      {children}
    </PartnerLoginContext.Provider>
  )
}

export type PartnerLoginProps = {
  useLegacyLogin: boolean
  partner: Partner
}

export const usePartnerLogin = ({
  useLegacyLogin = true,
  partner,
}: PartnerLoginProps) => {
  const { query } = useRouter()
  const [resolved, setResolved] = useState(query.eonxCheckInId ? false : true)
  const { logEvent } = useLogger()
  const { venueSlug } = useVenueContext()
  const router = useRouter()
  const [{ error: eonxCheckInGuestError }, eonxCheckInGuest] =
    useEonxCheckInGuestMutation()
  const [{ error: eonxLoginWithPartnerError }, eonxLoginWithPartner] =
    useEonxLoginWithPartnerMutation()
  const [, resetCart] = useResetCartMutation()

  const onEonxSuccess = useCallback(() => {
    Cookies.set(ORIGIN_COOKIE, 'eonx')
    updateEonxEnvironment()
    setResolved(true)
  }, [])

  /*
    Reset cookies during a check-in error
  */
  const onEonxError = useCallback(() => {
    Cookies.set(ORIGIN_COOKIE, 'none')
    updateEonxEnvironment()
    setResolved(true)
  }, [])

  // remove eonxCheckInId from URL after login
  const replaceCleanPath = useCallback(async () => {
    await Router.replace(
      getCleanPath(router.pathname, {
        sheet: router.query?.sheet,
      }),
      router.asPath
        ? getCleanPath(router.asPath, {
            sheet: router.query?.sheet,
          })
        : undefined,
      { shallow: true },
    )
  }, [router])

  const eonxCheckIn = useCallback(
    async ({ eonxCheckInId }: { eonxCheckInId: string }) => {
      const { error } = !useLegacyLogin
        ? await eonxLoginWithPartner({
            authToken: eonxCheckInId,
          })
        : await eonxCheckInGuest({
            id: eonxCheckInId,
            venueSlug,
          })

      if (!error) {
        if (!useLegacyLogin) {
          await resetCart({ partner: Partner.Eonx })
        }
        onEonxSuccess()
        logEvent(`Eonx check in success`, { venueSlug })
      } else {
        onEonxError()
        logEvent(`Eonx check in failure`, { venueSlug })
      }

      // remove eonxCheckInId from URL
      void replaceCleanPath()
    },
    [
      eonxCheckInGuest,
      onEonxSuccess,
      onEonxError,
      logEvent,
      resetCart,
      useLegacyLogin,
      eonxLoginWithPartner,
      venueSlug,
      replaceCleanPath,
    ],
  )

  useEffectOnce(() => {
    if (!resolved && query.eonxCheckInId && partner === Partner.Eonx) {
      logEvent(`Eonx check in ID found`)
      void eonxCheckIn({
        eonxCheckInId: String(query.eonxCheckInId),
      })
    }
  })

  return {
    partnerResolved: resolved,
    partnerError: eonxCheckInGuestError || eonxLoginWithPartnerError,
  }
}
